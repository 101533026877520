import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%"  viewBox="0 0 1080.000000 1080.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g 
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd" transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)">


<path d="M5090 10704 c-708 -38 -1311 -235 -1890 -618 -683 -452 -1209 -1155
-1456 -1948 -33 -107 -92 -349 -113 -464 -60 -330 -66 -802 -15 -1154 100
-688 405 -1351 863 -1875 128 -147 333 -345 451 -435 25 -19 47 -38 50 -41 3
-3 57 -44 120 -89 817 -591 1854 -816 2845 -619 991 197 1849 787 2394 1649
77 121 231 424 286 565 138 348 224 705 255 1055 13 150 13 461 0 631 -39 498
-211 1054 -462 1489 -70 121 -158 264 -167 270 -4 3 -27 34 -51 70 -24 36 -46
67 -50 70 -3 3 -21 25 -40 50 -126 164 -397 439 -580 586 -484 390 -1069 654
-1680 758 -204 35 -577 59 -760 50z m555 -655 c168 -19 455 -81 600 -130 186
-63 239 -86 429 -181 360 -180 702 -450 959 -756 384 -460 613 -987 699 -1612
20 -146 16 -594 -6 -750 -61 -433 -200 -826 -423 -1195 -43 -71 -86 -139 -95
-150 -9 -11 -39 -49 -65 -85 -26 -36 -55 -74 -65 -85 -9 -11 -33 -40 -53 -65
-125 -151 -315 -329 -501 -469 -410 -307 -864 -496 -1384 -575 -802 -122
-1654 99 -2299 597 -206 158 -320 272 -544 537 -36 42 -167 231 -204 295 -61
103 -74 127 -128 235 -164 328 -267 685 -305 1059 -15 150 -12 485 5 632 30
259 112 591 195 789 18 41 42 100 55 130 89 211 293 532 467 734 88 103 288
298 388 377 246 197 412 296 745 449 235 108 662 210 965 230 143 10 432 4
565 -11z"/>
<path d="M3346 2075 c-35 -36 -5 -92 43 -80 31 8 28 5 35 35 8 31 -2 47 -35
55 -18 4 -31 1 -43 -10z"/>
<path d="M7700 2070 c-25 -25 -25 -35 1 -61 14 -14 27 -18 42 -13 45 14 52 69
11 84 -33 13 -31 13 -54 -10z"/>
<path d="M2635 1899 l0 -59 49 0 49 0 -6 37 c-7 43 -22 60 -62 72 l-30 8 0
-58z"/>
<path d="M2936 1951 c-3 -5 1 -12 10 -15 15 -6 15 -8 -1 -26 -26 -28 -12 -52
27 -48 29 3 33 7 36 34 2 19 -3 38 -13 48 -18 17 -50 21 -59 7z"/>
<path d="M3555 1947 c-38 -13 -63 -41 -75 -86 -11 -43 1 -104 25 -131 22 -24
77 -43 107 -37 l23 4 -23 8 c-13 4 -28 19 -33 32 -12 32 -12 139 1 182 5 19 9
35 7 36 -1 1 -15 -2 -32 -8z"/>
<path d="M3803 1939 c-48 -24 -63 -53 -63 -122 1 -75 42 -117 115 -117 38 0
39 1 15 13 -13 7 -27 23 -31 37 -11 40 -10 160 2 182 15 29 8 30 -38 7z"/>
<path d="M3885 1900 l0 -60 48 0 c51 0 58 11 37 65 -8 20 -59 55 -82 55 -2 0
-3 -27 -3 -60z"/>
<path d="M5012 1943 c-49 -24 -66 -56 -65 -120 2 -65 22 -99 68 -119 45 -18
48 -18 34 8 -7 14 -10 60 -7 135 2 62 4 113 4 113 -1 0 -16 -8 -34 -17z"/>
<path d="M5100 1940 c8 -14 11 -61 8 -136 -1 -63 -2 -114 0 -114 11 0 60 32
76 49 15 16 20 37 20 86 1 59 -2 67 -29 94 -16 16 -43 33 -59 37 -27 6 -28 6
-16 -16z"/>
<path d="M5730 1950 c-13 -8 -12 -10 3 -10 21 0 22 -19 1 -34 -26 -19 -7 -48
30 -44 27 3 31 7 34 38 5 48 -31 74 -68 50z"/>
<path d="M5983 1944 c-48 -24 -67 -60 -67 -126 -1 -51 3 -61 30 -89 22 -21 44
-32 75 -36 l44 -6 -28 27 c-28 26 -28 28 -25 119 2 50 7 100 12 110 10 21 1
21 -41 1z"/>
<path d="M6078 1929 c3 -17 0 -29 -7 -29 -19 0 -12 -28 11 -44 29 -20 58 -4
58 32 0 35 -10 49 -41 60 -24 10 -25 9 -21 -19z"/>
<path d="M6233 1939 c-46 -23 -63 -56 -63 -121 0 -55 24 -95 67 -113 20 -8 38
-14 39 -12 2 2 -1 19 -6 38 -6 18 -10 61 -10 94 0 33 4 76 10 94 5 19 8 36 7
38 -2 1 -22 -7 -44 -18z"/>
<path d="M6326 1922 c15 -44 18 -144 5 -190 -9 -29 -8 -32 13 -32 47 0 86 60
86 131 0 69 -32 112 -92 123 -25 4 -25 4 -12 -32z"/>
<path d="M6632 1951 c-10 -6 -10 -10 3 -21 12 -10 15 -35 15 -121 l0 -109 48
0 47 0 -3 107 c-3 101 -5 110 -28 131 -26 23 -59 28 -82 13z"/>
<path d="M7195 1899 l0 -59 48 0 c43 0 47 2 47 24 0 31 -33 72 -67 84 l-28 9
0 -58z"/>
<path d="M7492 1951 c-11 -7 -11 -9 2 -14 13 -5 16 -26 16 -122 l0 -115 48 0
47 0 -3 107 c-3 101 -5 110 -28 131 -26 23 -59 28 -82 13z"/>
<path d="M8270 1951 c-12 -9 -12 -11 3 -17 15 -5 17 -21 17 -120 l0 -114 45 0
45 0 0 108 c0 93 -3 111 -18 125 -23 21 -72 31 -92 18z"/>
<path d="M8503 1939 c-22 -11 -45 -31 -52 -45 -17 -38 -14 -121 6 -151 19 -29
69 -53 110 -53 l28 0 -30 24 c-29 24 -30 26 -30 114 0 58 4 94 13 105 21 25
-1 28 -45 6z"/>
<path d="M8773 1945 c-83 -35 -100 -184 -25 -227 35 -20 90 -30 97 -18 3 6 -1
10 -9 10 -8 0 -21 10 -27 22 -15 28 -21 162 -8 195 5 14 8 27 7 29 -2 1 -18
-4 -35 -11z"/>
<path d="M8846 1943 c5 -10 8 -37 8 -60 l0 -43 43 0 c39 0 43 2 43 26 0 38
-18 64 -55 80 -44 18 -49 18 -39 -3z"/>
<path d="M2304 1942 c-17 -11 -34 -48 -34 -74 0 -39 26 -64 91 -87 57 -22 61
-24 57 -51 -4 -25 -2 -27 13 -20 21 12 29 32 29 74 0 45 -16 63 -85 89 -54 21
-60 27 -58 50 2 30 2 30 -13 19z"/>
<path d="M2430 1940 c-13 -8 -13 -11 3 -27 16 -16 17 -16 17 10 0 30 0 30 -20
17z"/>
<path d="M2533 1926 c-58 -49 -62 -145 -9 -195 28 -26 87 -43 107 -30 11 7 10
9 -3 9 -29 0 -46 49 -46 130 0 41 4 83 9 92 15 28 -23 23 -58 -6z"/>
<path d="M2788 1825 l3 -125 47 0 47 0 -1 108 c-1 154 3 142 -51 142 l-48 0 3
-125z"/>
<path d="M3049 1903 c82 -206 77 -198 105 -201 22 -3 29 2 40 30 9 20 10 35 5
39 -13 8 -69 160 -63 171 3 4 -20 8 -50 8 l-56 0 19 -47z"/>
<path d="M3328 1825 l3 -125 51 0 c42 0 49 3 44 16 -3 9 -6 65 -6 125 l0 109
-47 0 -48 0 3 -125z"/>
<path d="M3644 1934 c4 -10 -1 -25 -11 -36 -17 -19 -17 -21 6 -39 41 -33 83 9
58 59 -14 31 -65 46 -53 16z"/>
<path d="M4680 1946 c0 -6 88 -238 92 -241 2 -2 14 -5 27 -7 20 -2 26 3 37 34
7 21 9 40 4 43 -5 3 -21 44 -37 90 l-27 85 -48 0 c-26 0 -48 -2 -48 -4z"/>
<path d="M5261 1841 c3 -65 10 -116 18 -125 15 -18 64 -30 90 -21 20 6 20 6 1
25 -17 17 -19 33 -17 125 l2 105 -50 0 -50 0 6 -109z"/>
<path d="M5416 1840 c1 -60 4 -116 7 -125 4 -10 20 -15 47 -15 l40 0 0 125 0
125 -47 0 -48 0 1 -110z"/>
<path d="M5582 1825 l1 -125 48 0 48 0 3 108 c4 145 5 142 -53 142 l-48 0 1
-125z"/>
<path d="M6488 1825 l3 -125 44 0 43 0 4 108 c5 144 5 142 -51 142 l-46 0 3
-125z"/>
<path d="M6831 1838 c27 -62 49 -119 49 -125 0 -8 13 -13 29 -13 39 0 60 46
38 82 -8 12 -25 56 -38 95 l-24 73 -51 0 -52 0 49 -112z"/>
<path d="M7098 1933 c-34 -21 -53 -65 -52 -118 1 -66 43 -110 109 -116 35 -3
38 1 14 20 -21 17 -24 29 -26 106 -2 47 2 95 7 106 9 15 7 19 -7 19 -10 0 -30
-8 -45 -17z"/>
<path d="M7348 1825 l3 -125 43 0 44 0 4 108 c6 143 6 142 -51 142 l-46 0 3
-125z"/>
<path d="M7685 1825 l0 -125 48 0 47 0 0 125 0 125 -47 0 -48 0 0 -125z"/>
<path d="M7868 1921 c-31 -28 -33 -33 -33 -96 0 -63 2 -68 33 -96 25 -23 41
-29 75 -28 37 0 39 2 20 11 -13 6 -25 20 -29 31 -10 33 -13 145 -4 177 7 26 6
30 -11 30 -10 0 -33 -13 -51 -29z"/>
<path d="M7981 1929 c6 -12 9 -37 7 -55 -4 -34 -4 -34 38 -34 52 0 59 9 40 54
-15 37 -41 56 -76 56 -18 0 -19 -3 -9 -21z"/>
<path d="M8125 1825 l0 -125 47 0 47 0 3 108 c4 145 5 142 -51 142 l-46 0 0
-125z"/>
<path d="M8607 1931 c3 -10 0 -22 -6 -25 -18 -12 -12 -45 10 -56 16 -9 25 -8
40 6 23 21 24 42 3 72 -19 28 -54 30 -47 3z"/>
<path d="M4470 1936 c0 -2 8 -10 18 -17 15 -13 16 -12 3 4 -13 16 -21 21 -21
13z"/>
<path d="M4270 1883 c-32 -22 -44 -40 -51 -83 -6 -30 11 -90 25 -90 3 0 10 -2
18 -5 10 -4 13 11 14 69 0 52 5 81 17 100 20 30 12 33 -23 9z"/>
<path d="M4373 1888 c-4 -7 -13 -44 -20 -82 -15 -90 0 -115 71 -116 19 0 19 0
1 14 -14 10 -18 25 -16 68 1 30 5 68 10 86 5 17 7 33 3 36 -10 11 -41 6 -49
-6z"/>
<path d="M1875 1767 c-13 -10 -16 -21 -11 -40 8 -34 41 -44 67 -21 23 21 24
37 3 58 -19 20 -35 20 -59 3z"/>
<path d="M2009 1759 c-18 -18 -19 -23 -6 -42 8 -12 22 -23 31 -25 42 -9 64 58
27 78 -26 13 -28 13 -52 -11z"/>
<path d="M2152 1768 c-17 -17 -15 -53 3 -68 12 -10 21 -10 40 -2 40 19 29 82
-15 82 -9 0 -21 -5 -28 -12z"/>
<path d="M9006 1761 c-32 -35 -2 -75 47 -63 26 7 33 50 11 67 -26 19 -38 18
-58 -4z"/>
<path d="M2270 1733 c0 -26 3 -30 20 -26 20 5 20 6 3 27 -10 11 -19 23 -20 25
-2 2 -3 -9 -3 -26z"/>
<path d="M4150 1691 c0 -6 4 -13 10 -16 6 -3 7 1 4 9 -7 18 -14 21 -14 7z"/>
<path d="M4678 1649 c-21 -12 -24 -52 -5 -66 16 -13 57 -23 57 -14 0 4 -5 12
-12 19 -9 9 -9 12 0 12 18 0 15 47 -4 54 -19 7 -15 8 -36 -5z"/>
<path d="M4796 1645 c-9 -26 -7 -32 5 -12 6 10 9 21 6 23 -2 3 -7 -2 -11 -11z"/>
<path d="M4230 1620 c8 -5 20 -10 25 -10 6 0 3 5 -5 10 -8 5 -19 10 -25 10 -5
0 -3 -5 5 -10z"/>
<path d="M4417 1624 c-3 -4 -21 -10 -39 -14 -29 -7 -30 -8 -7 -9 14 -1 34 6
45 14 10 8 16 15 13 15 -3 0 -8 -3 -12 -6z"/>
<path d="M4283 1603 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
</g>
</svg>



    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
